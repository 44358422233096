<template>
    <div>
        <div class="row justify-content-center">
            <div class="col-12 pl-3">
              <button type="button" @click="$router.go(-1)" class="btn btn-sm btn-style4 text-sm my-3"><span class="typcn typcn-arrow-left"></span> Back</button>
            </div>
          <div class="col-md-11">
              <div class="shadow rounded-lg p-3" v-if="pelanggan.length>0">
                  <p class="text-lg font-semibold">Review</p>
                  <hr class="my-2">
                  <!-- <button type="button"  class="btn btn-sm btn-dark  my-2">REVIEW</button> -->
                  <div class="row">
                      <div class="col-6">
                        <div class="shadow p-3 rounded">
                            <p class="text-lg font-semibold">Staff yang menangani : </p>
                            <hr class="my-2">
                            <table>
                                <tr>
                                    <td>Dokter</td>
                                    <td class="px-2">:</td>
                                    <td>{{dokter.length>0?dokter[0].nama:'----'}}</td>
                                </tr>
                                <tr>
                                    <td>Nurse</td>
                                    <td class="px-2">:</td>
                                    <td>{{nurse.length>0?nurse[0].nama:'----'}}</td>
                                </tr>
                                <tr>
                                    <td>Facial</td>
                                    <td class="px-2">:</td>
                                    <td>{{facial.length>0?facial[0].nama:'----'}}</td>
                                </tr>
                                 <tr>
                                    <td>Apoteker</td>
                                    <td class="px-2">:</td>
                                    <td>{{apoteker.length>0?apoteker[0].nama:'----'}}</td>
                                </tr>
                                <tr>
                                    <td>Admin/Kasir</td>
                                    <td class="px-2">:</td>
                                    <td>{{adminapotek.length>0?adminapotek[0].nama:'----'}}</td>
                                </tr>
                            </table>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="shadow p-3 rounded font-semibold">
                            <p class="">Pelanggan: {{pelanggan[0].nama}}</p>
                            <p class="" v-if="history.length>0">Tanggal : {{format(history[0].tanggal)}}</p>
                            <hr class="my-3">
                        </div>
                      </div>
                  </div>
                    <hr class="my-2">
                    <div class="row justify-content-center">
                      <div class="col-md-6">
                        <p class="text-lg font-semibold">Keluhan</p>
                        <hr class="my-2">
                        <div class="sm-form">
                        <div v-if="$route.query.level=='dokter' || $route.query.level=='SuperAdmin'">
                            <p>Keluhan : </p>
                                <textarea type="text" id="keluhan" name="keluhan" rows="2" placeholder="keluhan..." class="form-control md-textarea" v-model="vdata.keluhan" ></textarea>
                            <hr class="my-2">
                            <p>Objective : </p>
                                <textarea type="text" id="objective" name="objective" rows="2" placeholder="objective..." class="form-control md-textarea" v-model="vdata.objective" ></textarea>
                            </div>
                            <div v-else>
                                <p>Keluhan : </p>
                                    <p v-text="vdata.keluhan" class="font-semibold"></p>
                                <hr class="my-2">
                                <p>Objective : </p>
                                    <p v-text="vdata.objective" class="font-semibold"></p>
                                </div>
                            </div>
                        </div>
                      <div class="col-md-6">
                        <p class="text-lg font-semibold">Diagnosis</p>
                        <hr class="my-2">
                        <div v-if="$route.query.level=='dokter' || $route.query.level=='SuperAdmin'">
                            <div class="p-2">
                                <button type="button" @click="$router.push('/admin/apotek/diagnosis')" class="btn btn-sm btn-dark  ">List Diagnosis</button>
                                <button type="button" @click="$router.push('/admin/apotek/plan')" class="btn btn-sm btn-dark  ml-3">List Plan</button>
                            </div>
                            <p class="">Diagnosis : </p>
                            <select class='form-control' v-model="vdata.diagnosis">
                                <option v-for="(item, index) in diagnosis" :key="index+'diagnosis'" >{{item.nama}}</option>
                            </select>
                            <hr class="my-2">
                           
                            <p class="">Plan : </p>
                            <select class='form-control' v-model="vdata.plan">
                                <option v-for="(item, index) in plan" :key="index+'plan'" >{{item.nama}}</option>
                            </select>
                            <hr class="my-2">
                            <p>Catatan : </p>
                                <textarea type="text" id="keluhan" name="keluhan" rows="2" placeholder="keluhan..." class="form-control md-textarea" v-model="vdata.plan_catatan" ></textarea>
                            <hr class="my-2">
                            <button type="button" @click="simpanReview" class="btn btn-sm btn-style4 text-sm"><span class="typcn typcn-edit"></span> Simpan Catatan Review</button>
                            <hr>
                            <table>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </table>
                        </div>
                        <div v-else>
                             <p class="">Diagnosis : </p>
                                <p v-text="vdata.diagnosis" class="font-semibold"></p>
                            <hr class="my-2">                           
                            <p class="">Plan : </p>
                                <p v-text="vdata.plan" class="font-semibold"></p>
                            <hr class="my-2">
                            <p>Catatan : </p>
                                <p v-text="vdata.plan_catatan" class="font-semibold"></p>
                            <hr class="my-2">
                        </div>
                      </div>
                    </div>
                    <div >
                        <div class="my-2 shadow rounded p-3">
                            <hr class="my-2">
                            <div class="p-3 shadow rounded" v-if="history[0].catatan_admin">
                                <p><span class="typcn typcn-document-text"></span> Catatan {{history[0].admin}} : </p>
                                <div class="text-justify font-semibold" v-text="history[0].catatan_admin"></div>
                                <hr class="my-2">
                            </div>
                            <div class="p-3 shadow rounded" v-if="history[0].catatan_dokter">
                                <p><span class="typcn typcn-document-text"></span> Catatan {{history[0].dokter}} : </p>
                                <div class="text-justify font-semibold" v-text="history[0].catatan_dokter"></div>
                                <hr class="my-2">
                            </div>
                            <div class="p-3 shadow rounded" v-if="history[0].catatan_nurse">
                                <p><span class="typcn typcn-document-text"></span> Catatan {{history[0].nurse}} : </p>
                                <div class="text-justify font-semibold" v-text="history[0].catatan_nurse"></div>
                                <hr class="my-2">
                            </div>
                            <div class="p-3 shadow rounded" v-if="history[0].catatan_facial">
                                <p><span class="typcn typcn-document-text"></span> Catatan {{history[0].facial}} : </p>
                                <div class="text-justify font-semibold" v-text="history[0].catatan_facial"></div>
                                  <hr class="my-2">
                            </div>
                            <div class="p-3 shadow rounded" v-if="history[0].catatan_apoteker">
                                <p><span class="typcn typcn-document-text"></span> Catatan {{history[0].apoteker}} : </p>
                                <div class="text-justify font-semibold" v-text="history[0].catatan_apoteker"></div>
                            </div>
                        </div>
                    </div>
                    </div>
              </div>
          </div>
    </div>
</template>
<script>
// ganti source autophp, ganti source component btable, ganti path table sdb
import autophp from "@/plugins/autophp_kasir.js"; // ganti source nya
let sdb = new autophp();
export default{
    data(){
        return{
            pelanggan:[],
            history:[],
            transaksi:[],
            diagnosis:[],
            plan:[],
            vdata:{},
            dokter:[],
            nurse:[],
            facial:[],
            adminapotek:[],
            apoteker:[],
            pencarian:''
        }
    },
    computed:{
        td(){
            let that=this;
            let data=this.history;
            data=data.filter(e=>{
                if(e.tanggal.indexOf(that.pencarian)!=-1){
                    return e;
                }
            })
            return data;
        }
    },
    methods: {
        simpanReview(){
            sdb.collection("app_kasir_pelanggan_history",false).doc().select(`update app_kasir_pelanggan_history set 
            keluhan='${this.vdata.keluhan}',
            objective='${this.vdata.objective}',
            diagnosis='${this.vdata.diagnosis}',
            plan='${this.vdata.plan}',
            catatan_review='${this.vdata.plan_catatan}'
            where id_transaksi_nota="${this.$route.query.nota}"
            `).then(res => {
                console.log(res);
                this.$router.go()
            });
            
        },
        getData(){
            let that=this;
            sdb.collection("app_kasir_pelanggan_history",false).doc().select(`select tb1.*,
            tb2.catatan_admin,
            tb2.catatan_dokter,
            tb2.catatan_apoteker,
            tb2.catatan_nurse,
            tb2.catatan_facial,
            tb3.nama as admin,
            tb4.nama as dokter,
            tb5.nama as nurse,
            tb6.nama as facial,
            tb7.nama as apoteker
            from app_kasir_pelanggan_history tb1 
            left join app_kasir_transaksi_nota tb2 on tb2.nota=tb1.id_transaksi_nota 
            left join tbuser tb3 on tb3.id=tb2.id_admin 
            left join tbuser tb4 on tb4.id=tb2.id_dokter 
            left join tbuser tb5 on tb5.id=tb2.id_nurse 
            left join tbuser tb6 on tb6.id=tb2.id_facial 
            left join tbuser tb7 on tb7.id=tb2.id_apoteker 
            where tb1.id_transaksi_nota='${this.$route.query.nota}'`).then(res => {
                this.history=res;
                this.vdata.keluhan=res[0].keluhan;
                this.vdata.objective=res[0].objective;
                this.vdata.diagnosis=res[0].diagnosis;
                this.vdata.plan=res[0].plan;
                this.vdata.plan_catatan=res[0].catatan_review;
                this.$forceUpdate();
            });
            sdb.collection("app_kasir_pelanggan",false).doc().select(`select * from app_kasir_pelanggan where id='${this.$route.query.pelanggan}'`).then(res => {
                this.pelanggan=res;
                this.$forceUpdate();
            });
            
            sdb.collection("app_kasir_diagnosis",false).doc().select(`select * from app_kasir_diagnosis`).then(res => {
                this.diagnosis=res;
                console.log('diagnosis',res)
                this.$forceUpdate();
            });
            sdb.collection("app_kasir_plan",false).doc().select(`select * from app_kasir_plan`).then(res => {
                this.plan=res;
                this.$forceUpdate();
            });

            sdb.collection("app_kasir_transaksi_nota",false).doc().select(`select id_admin,id_apoteker,id_nurse,id_dokter from app_kasir_transaksi_nota where nota="${this.$route.query.nota}"`).then(res => {
                if(res.length>0){
                    if(res[0].id_admin){
                        sdb.collection("tbuser",false).doc().select(`select * from tbuser where id="${res[0].id_admin}"`).then(res => {
                            this.adminapotek=res;
                            this.$forceUpdate();
                        });
                    }
                    if(res[0].id_dokter){
                        sdb.collection("tbuser",false).doc().select(`select * from tbuser where id="${res[0].id_dokter}"`).then(res => {
                            this.dokter=res;
                            this.$forceUpdate();
                        });
                    }
                    if(res[0].id_nurse){
                        sdb.collection("tbuser",false).doc().select(`select * from tbuser where id="${res[0].id_nurse}"`).then(res => {
                            this.nurse=res;
                            this.$forceUpdate();
                        });
                    }
                     if(res[0].id_facial){
                        sdb.collection("tbuser",false).doc().select(`select * from tbuser where id="${res[0].id_facial}"`).then(res => {
                            this.facial=res;
                            this.$forceUpdate();
                        });
                    }
                    if(res[0].id_apoteker){
                        sdb.collection("tbuser",false).doc().select(`select * from tbuser where id="${res[0].id_apoteker}"`).then(res => {
                            this.apoteker=res;
                            this.$forceUpdate();
                        });
                    }
                }
            });
          
                        
        },
        format(tgl) {
        return this.$datefns.format(
            new Date(tgl),
            "DD-MM-YYYY"
        );
    },
    },
    layout:"app_shop_admin",
    async mounted() {
        if(this.$store.state.users.level=='dokter'){
            await sdb.collection("tbuser",false).doc().select(`update app_kasir_transaksi_nota set id_dokter='${this.$store.state.users.id}' where nota='${this.$route.query.nota}'`)
        }else if(this.$store.state.users.level=='nurse'){
            await sdb.collection("tbuser",false).doc().select(`update app_kasir_transaksi_nota set id_nurse='${this.$store.state.users.id}' where nota='${this.$route.query.nota}'`)
        }else if(this.$store.state.users.level=='apoteker'){
            await sdb.collection("tbuser",false).doc().select(`update app_kasir_transaksi_nota set id_apoteker='${this.$store.state.users.id}' where nota='${this.$route.query.nota}'`)
        }else if(this.$store.state.users.level=='facial'){
            await sdb.collection("tbuser",false).doc().select(`update app_kasir_transaksi_nota set id_facial='${this.$store.state.users.id}' where nota='${this.$route.query.nota}'`)
        }
        this.getData();
    },
}
</script>